.applyWaitlist {
  padding: 2rem;
  text-align: center;
}
.title,
.applyWaitlistButton {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  font-style: normal;
  color: white;
}
.applyWaitlistButton {
  color: black;
  border-radius: 0.5rem;
  width: 15rem;
}

.emailInput {
  height: 1.5rem;
  width: 70%;
}

.wrongEmailAlert {
  color: red;
  display: block;
  font-weight: bold;
}
