.App {
  text-align: center;
  background-color: black;
  color: white;
}

.login-button {
  margin-top: 0rem;
  font-weight: bold;
  font-family: 'Press Start 2P', system-ui;
  font-weight: 400;
  font-style: normal;
}

.check-privacy-button {
  margin-top: 6rem;
  font-weight: bold;
  font-family: 'Press Start 2P', system-ui;
  font-weight: 400;
  font-style: normal;
}
