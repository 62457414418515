.mainDiv {
  color: white;
  width: 90%;
  max-width: 30rem;
  margin: 0 auto;
  padding: 1rem;
}

.backButton {
  margin: 1.5rem auto;
  cursor: pointer;
}
.mainTextDiv {
  width: 100%;
  white-space: pre;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.mainText {
  white-space: pre-line;
  line-height: 1.5rem;
}
