.userProfileDiv,
.userInsightsDiv,
.followersDemoDiv,
.userInsightsGraphDiv,
.myThreadsListDiv {
  /* border: white solid 1px; */
  padding: 3rem;
  text-align: center;
  color: white;
}

.sectionDivider {
  background-color: white;
}

.userProfilePicture {
  width: 100px;
  border-radius: 100%;
}

.userProfileDiv > h5 {
  width: 25rem;
  max-width: 90%;
  margin: 0 auto;
  white-space: pre-wrap;
}

.userInsightsRow,
.followersDemoRow {
  max-width: 30rem;
  margin: 0 auto;
}

.userInsightsCol {
  padding: 4px;
}

.insightDesc {
  word-break: keep-all;
}

.insightValueNumber {
  font-size: 1rem;
}

.userInsightsGraphDiv {
  color: white;
  padding: 0;
}

.datesRenderDiv,
.demoTitle {
  font-family: 'Montserrat', sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.datesRenderDiv {
  margin-top: 3rem;
}

.demoTitle {
  font-weight: 900;
}

.followersDemoRow {
  padding: 1rem;
}

.eachSegment {
  width: 3rem;
}

.dateRangeDiv {
  max-width: 15rem;
  margin: 2rem auto;
}

.dateRangePicker {
  width: 100%;
}

.reDoButton {
  margin: 1rem auto 2rem;
  width: 100%;
  font-weight: bold;
}

.myThreadsListDiv {
  padding: 0rem;
}

.myThreadsListInnerDiv {
  max-width: 50rem;
  margin: 0 auto;
}

.myThreadsListLoadMoreButton {
  margin: 1.5rem auto;
  font-family: 'Press Start 2P', system-ui;
  font-size: 0.7rem;
}

.listStyleSegmented {
  margin: 1rem auto 2rem;
}
