.appTitle {
  margin-top: 10rem;
  font-family: 'Press Start 2P', system-ui;
  font-weight: 400;
  font-style: normal;
  line-height: 3rem;
}

.developersDiv {
  text-align: center;
  font-family: 'Press Start 2P', system-ui;
  color: white;
  height: 50px;
  line-height: 50px;
  align-items: center;
  font-size: 0.5rem;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.threadsLink,
.threadsLink:visited,
threadsLink:hover,
threadsLink:active,
threadsLink:focus {
  color: white;
  text-decoration: none;
}

.threadsLogo {
  justify-content: center;
  vertical-align: middle;
  margin-right: 0.2rem;
}

.threadsName {
  display: inline-block;
  vertical-align: middle;
}
