.modalCloseButton {
  color: white;
  font-weight: bold;
  position: sticky;
  right: 0;
  top: 0;
  width: max-content;
  margin-left: auto;
  cursor: pointer;
}

.insightDivider {
  background-color: white;
  margin: 1rem auto;
}

.insightPreviewCol,
.insightPreviewRow {
  justify-content: center;
  margin: 0 auto;
}

.insightPreviewRow {
  margin: 0.2rem 0;
}

.insightPreviewSpan {
  margin: 0 0.2rem;
  word-wrap: normal;
  font-size: 0.7rem;
}

/* IN-MODAL */

.modalFooter {
  width: 90%;
  max-width: 30rem;
  margin: 0 auto;
  padding: 1rem;
}

.modalOkButton {
  color: black;
  font-weight: bold;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  width: 4rem;
  height: 2rem;
  cursor: pointer;
}

.goToOriginal {
  color: white;
  font-weight: bold;
  width: max-content;
  margin-left: auto;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px solid white;
  padding: 0.5rem;
}

.goToOriginalLink,
.goToOriginalLink:visited,
goToOriginalLink:hover,
goToOriginalLink:active,
goToOriginalLink:focus {
  color: white;
  text-decoration: none;
}

/* GRID STYLE */
.myThreadsListCol {
  overflow: hidden;
  border: 0.001rem solid white;
  padding: 0.6rem;
  border-radius: 1rem;
  cursor: pointer;
}

/* LIST STYLE */
.sectionDivider {
  background-color: white;
  margin: 0;
}

.listStyleItem {
  text-align: left;
  cursor: pointer;
}

/* INSIGHT TABLE */
.insightTableRow {
  text-align: end;
}

.insightTableCol {
  margin: 0.2rem auto;
}
